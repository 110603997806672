<template>
    <div class="text-center">
        <v-dialog v-if="salePercent || saleBDT || saleText" v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
            </template>
            <v-card height="400">
                <vs-row justify="center">
                    <vs-col :style="{ textAlign: 'center' }" :w="bestSellers.length ? '6' : '12'">
                        <p :style="{ padding: '2%', fontSize: '28px', fontWeight: 500, color: COLORS.SECONDARY }">
                            Welcome to {{ BRAND_NAME }} </p>
                        <p v-if="salePercent"
                            :style="{ padding: '0.5%', fontSize: '28px', fontWeight: 'bold', color: COLORS.SECONDARY, border: '2px' }">
                            {{ salePercent }}% OFF </p>
                        <p v-if="saleBDT"
                            :style="{ padding: '0.5%', fontSize: '28px', fontWeight: 'bold', color: COLORS.SECONDARY, border: '2px' }">
                            <i class="fa-solid fa-bangladeshi-taka-sign"></i> {{ saleBDT }} OFF
                        </p>
                        <p v-if="saleText" style="padding: 1%;"> {{ saleText }} </p>
                        <v-btn rounded style="text-decoration: underline;" text @click="dialog = false">
                            Let's Shop
                        </v-btn>
                    </vs-col>
                    <vs-col v-if="bestSellers.length" w="6">
                        <v-carousel interval="3000" cycle height="400px" hide-delimiter-background hide-delimiters
                            :show-arrows="false">
                            <v-carousel-item v-for="item in shuffleArray(bestSellers)" :key="item.productImages[0]"
                                :src="item.productImages[0]" cover></v-carousel-item>
                        </v-carousel>
                    </vs-col>
                </vs-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';
import { BRAND_NAME } from '@/plugins/utils.js';

export default {
    name: 'WelcomeDialogue',
    props: ['text', 'amount', 'products'],
    components: {},
    data: () => ({
        dialog: true,
        COLORS,
        BRAND_NAME
    }),
    computed: {
        salePercent() {
            return this.$store.state.salePercent;
        },
        saleBDT() {
            return this.$store.state.saleBDT;
        },
        saleText() {
            return this.$store.state.saleText;
        },
        bestSellers() {
            return this.$store.state.bestSellers;
        },
    },
    methods: {
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        }
    },
};
</script>